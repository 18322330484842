import styled from 'styled-components/native'

export const SwitchContainer = styled.View`
  margin-left: 32px;
  margin-top: 32px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const SwitchTextContainer = styled.View`
`

export const SwitchButton = styled.Switch`
  height: 32px;
`
