import { useUserInfo } from '@core/services/viewer'
import { Line, Title } from '@ui/Moodwork-UI/atoms'
import { Button } from '@ui/Moodwork-UI/molecules'
import { TextInput } from '@ui/TextInput'
import React from 'react'

export default function ProfileMenu() {
  const { data } = useUserInfo()
  return (
    <>
      <Title
        style={{ paddingHorizontal: 32, paddingTop: 8, paddingBottom: 24 }}
        weight='regular'
        marginSize='0'>
        Profil
      </Title>
      <Line height={2} color='separatorGrey' />
      <TextInput
        style={{ marginLeft: 32, marginTop: 16, width: '50%' }}
        type='text'
        label='Votre prénom'
        value={data?.name}
        disabled
      />
      <TextInput
        style={{ marginLeft: 32, marginTop: 8, width: '50%' }}
        type='text'
        label='Votre email professionnel'
        value={data?.email}
        disabled
      />
      <TextInput
        style={{
          marginLeft: 32,
          marginTop: 8,
          marginBottom: 16,
          width: '50%',
        }}
        type='text'
        label='Votre email privée'
      />
      <Button
        compact
        label='Modifier votre email'
        style={{ width: '33%', marginLeft: 32, marginBottom: 32 }}
      />
      <Line height={2} color='separatorGrey' />
      <Title
        style={{ paddingHorizontal: 32, paddingVertical: 16 }}
        weight='regular'
        marginSize='0'>
        Modifier votre mot de passe
      </Title>
      <TextInput
        style={{ marginLeft: 32, marginTop: 16, width: '50%' }}
        type='text'
        label='Mot de passe actuel'
      />
      <TextInput
        style={{ marginLeft: 32, marginTop: 8, width: '50%' }}
        type='text'
        label='Nouveau mot de passe'
      />
      <TextInput
        style={{
          marginLeft: 32,
          marginTop: 8,
          marginBottom: 16,
          width: '50%',
        }}
        type='text'
        label='Confirmer votre nouveau mot de passe'
      />
      <Button
        compact
        label='Modifier votre mot de passe'
        style={{ width: '33%', marginLeft: 32 }}
      />
    </>
  )
}
