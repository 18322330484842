import React from 'react'
import { Text } from '@ui/Moodwork-UI/atoms'
import { Button } from './index.styles'
import { ProfileLeftMenuType } from './index.types'

export default function ProfileLeftMenu({
  activeIndex,
  setActiveIndex,
}: ProfileLeftMenuType) {
  return (
    <>
      <Button isActive={activeIndex === 0} onPress={() => setActiveIndex(0)}>
        <Text
          size='M'
          weight='medium'
          color={activeIndex === 0 ? 'white' : 'secondary'}>
          Profile
        </Text>
        <Text
          size='M'
          weight='medium'
          color={activeIndex === 0 ? 'white' : 'secondary'}>
          Vos informations personnelles
        </Text>
      </Button>
      <Button isActive={activeIndex === 1} onPress={() => setActiveIndex(1)}>
        <Text
          size='M'
          weight='medium'
          color={activeIndex === 1 ? 'white' : 'secondary'}>
          Emails
        </Text>
        <Text
          size='M'
          weight='medium'
          color={activeIndex === 1 ? 'white' : 'secondary'}>
          Paramétrez vos emails
        </Text>
      </Button>
      <Button isActive={activeIndex === 2} onPress={() => setActiveIndex(2)}>
        <Text
          size='M'
          weight='medium'
          color={activeIndex === 2 ? 'white' : 'secondary'}>
          Données personnelles
        </Text>
        <Text
          size='M'
          weight='medium'
          color={activeIndex === 2 ? 'white' : 'secondary'}>
          Gérez vos données personnelles
        </Text>
      </Button>
    </>
  )
}
