import { PageContent } from '@ui/Moodwork-UI/templates/Page'
import styled from 'styled-components/native'

export const Container = styled(PageContent)<{ big: boolean }>`
  width: 100%;
  height: 100%;
  padding-top: 64px;
  padding-horizontal: 256px;
  margin-bottom: 128px;
  align-items: center;
`

export const Menu = styled.View`
  flex: 1;
  flex-direction: row;
  width: 100%;
`

export const LeftMenu = styled.View`
  flex: 1;
`

export const RightMenu = styled.View`
  padding-top: 8px;
  flex: 3;
`
