import { Line, Link, SubTitle, Text, Title } from '@ui/Moodwork-UI/atoms'
import React from 'react'

export default function PersonnalDataMenu() {
  return (
    <>
      <Title
        style={{ paddingHorizontal: 32, paddingTop: 8, paddingBottom: 24 }}
        weight='regular'
        marginSize='0'>
        Données personnelles
      </Title>
      <Line height={2} color='separatorGrey' />
      <Text
        weight='bold'
        size='L'
        style={{ paddingHorizontal: 32, paddingTop: 32, paddingBottom: 16 }}>
        Télécharger vos informations
      </Text>
      <Text style={{ paddingHorizontal: 32, paddingTop: 8, paddingBottom: 16 }}>
        Il s’agit d’une copie de vos informations personnelles que vous avez
        partagées sur Moodwork. Afin de protéger vos données, nous vous
        demanderons de saisir de nouveau votre mot de passe pour confirmer qu’il
        s’agit bien de votre compte.
      </Text>
      <Link
        style={{ paddingHorizontal: 32, paddingBottom: 32 }}
        text='Télécharger'
      />
      <Line height={2} color='separatorGrey' />
      <Text
        weight='bold'
        size='L'
        style={{ paddingHorizontal: 32, paddingTop: 32, paddingBottom: 16 }}>
        Supprimer votre compte
      </Text>
      <Text style={{ paddingHorizontal: 32, paddingTop: 8, paddingBottom: 16 }}>
        Si vous ne pensez jamais réutiliser Moodwork et souhaitez effacer
        complètement votre compte, nous pouvons nous en charger. Sachez
        cependant que vous ne pourrez ni réactiver votre compte ni récupérer son
        contenu ou ses informations. Nous vous demanderons de saisir de nouveau
        votre mot de passe pour confirmer qu’il s’agit bien de votre compte.
      </Text>
      <Link
        style={{ paddingHorizontal: 32, paddingBottom: 24 }}
        text='Supprimer'
      />
    </>
  )
}
