import { Line, Paragraph, Text, Title } from '@ui/Moodwork-UI/atoms'
import React, { useEffect, useState } from 'react'
import {
  SwitchButton,
  SwitchContainer,
  SwitchTextContainer,
} from './index.styles'
import { useTheme } from '@ui/utils'

export default function EmailMenu() {
  const [mailValue, setMailValue] = useState(false)
  const [newsletterValue, setNewsletterValue] = useState(false)
  const theme = useTheme()

  useEffect(() => {
    if (mailValue) {
      setNewsletterValue(true)
    }
    if (!mailValue) {
      setNewsletterValue(false)
    }
  }, [mailValue])

  return (
    <>
      <Title
        style={{ paddingHorizontal: 32 }}
        weight='regular'
        marginSize='-8px 0 0 0'>
        Emails
      </Title>
      <Text style={{ paddingHorizontal: 32, paddingBottom: 16 }}>
        Recevoir des emails informatifs
      </Text>
      <Line height={2} color='separatorGrey' />
      <SwitchContainer>
        <SwitchTextContainer>
          <Text>Emails de communication</Text>
          <Text size='XS'>
            Recevoir des emails de communication de la part de Moodwork
          </Text>
        </SwitchTextContainer>
        <SwitchButton
          activeThumbColor={theme.colors.secondary}
          trackColor={{
            true: theme.colors.secondaryLight,
            false: theme.colors.lightSecondaryGrey,
          }}
          value={mailValue}
          onValueChange={setMailValue}
        />
      </SwitchContainer>
      <SwitchContainer>
        <SwitchTextContainer>
          <Text>Newsletter</Text>
          <Text size='XS'>Recevoir des mails infortmatifs sur Moodwork</Text>
        </SwitchTextContainer>
        <SwitchButton
          activeThumbColor={theme.colors.secondary}
          trackColor={{
            true: theme.colors.secondaryLight,
            false: theme.colors.lightSecondaryGrey,
          }}
          value={newsletterValue}
          onValueChange={(value) => {
            if (mailValue) {
              setNewsletterValue(value)
            }
          }}
        />
      </SwitchContainer>
    </>
  )
}
